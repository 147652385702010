<template>
	<div class="sticky-list-container">
		<div class="sticky-list mdc-typography">
			<div>
				<slot name="header"></slot>
			</div>
			<h4 v-if="listTitle !== undefined" class="mdc-typography--headline6">{{ listTitle }}</h4>
			<nav class="mdc-list">
				<div
					v-for="item in listItems"
					v-show="item.hidden !== false"
					:key="item.id || item.index"
				>
					<hr v-if="item.divider === true"
						class="mdc-list-divider"
					>
					<a v-else
					   :class="{
							'mdc-list-item': true,
							'mdc-list-item--activated': item.active || isActive(item),
							'indented': item.indent === true
						}"
					   :href="item.href"
					   :style="extraStyles(item)"
					   @click="clickHandler($event, item)"
					>
						<i v-if="item.icon" :style="extraStyles(item)"
						   aria-hidden="true"
						   class="material-icons mdc-list-item__graphic"
						>{{ item.icon }}</i>
						<span :style="extraStyles(item)" class="mdc-list-item__text">{{ item.label }}</span>
						<span v-if="item.meta" :style="extraStyles(item)" aria-hidden="true"
							  class="mdc-list-item__meta">{{ item.meta }}</span>
					</a>
				</div>
			</nav>
			<div>
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: [
		'listTitle',
		'listItems',
		'navigate',
	],
	data () {
		return {
			appBarElement: undefined,
			listElement: undefined,
			intersectionObserver: undefined,
			mutationObserver: undefined,
		}
	},
	mounted () {
		this.appBarElement = document.getElementById('app-bar')
		this.listElement = this.$el.children[0]

		// I attempted to use IntersectionObserver and MutationObserver to
		// implement sticky when the element is scrolled out of view, but
		// the interaction with the app bar caused problems which these
		// are unable to observe. Although it got 90% of the way there.
		//
		// Specifically I can't find a way to detect changes in the viewport
		// relative position an element (while staying within the viewport).
		//
		// As such I am just using a simple old event listeners instead.
		// It's less efficient but works perfectly.
		//
		addEventListener('scroll', this.adjustListPosition, {passive: true})
		addEventListener('resize', this.adjustListPosition, {passive: true})
	},
	beforeDestroyed () {
		removeEventListener('resize', this.adjustListPosition)
		removeEventListener('scroll', this.adjustListPosition)
	},
	methods: {
		extraStyles (item) {
			if (item.color !== undefined) {
				return 'color: ' + item.color + ';'
			}
			return ''
		},
		clickHandler (event, item) {
			event.preventDefault()
			// console.debug('stickyList clickHandler', event, item, this)
			if (item.callback === undefined) {
				if (this.navigate !== true) {
					this.$emit('click', [event, item, this])
				} else {
					this.$router.push({
						'name': item.id,
						'params': item.routeParams
					})
				}
			} else {
				item.callback([event, item])
			}
		},
		isActive (item) {
			return !!this.$route.matched.find(route => route.name === item.id)
		},
		adjustListPosition () {
			let containerBounding = this.$el.getBoundingClientRect()

			const topBarHeight = 64
			let targetTop = topBarHeight + this.appBarElement.offsetTop
			if (targetTop < 0) {
				targetTop = 0
			}
			if (this.appBarElement.offsetTop >= -64) {
				this.listElement.classList.add('sticky-list-avoid-top-bar')
				this.listElement.style.top = targetTop + 'px'
			} else {
				this.listElement.classList.remove('sticky-list-avoid-top-bar')
				this.listElement.style.top = '0px'
			}

			if (containerBounding.top > targetTop) {
				// move the list back to being inline in the page content
				this.listElement.classList.remove('sticky-list-fixed')
				this.listElement.classList.add('sticky-list-relative')
				this.listElement.style.width = 'auto'
			} else {
				// move the list to be fixed just below the top app bar
				this.listElement.classList.remove('sticky-list-relative')
				this.listElement.classList.add('sticky-list-fixed')
				this.listElement.style.width = containerBounding.width + 'px'
			}
		},

	},
}

</script>

<style lang="scss" scoped>

.sticky-list-container {
	min-height: 1em;
	//border: 1px dashed blue;
}

// Position sticky doesn't work here because of the way that the main contents
// of the pages get laid out. So it's been fixed in javascript instead, which is
// actually better because it allows us to take into account the space consumed
// by the top app bar.
.sticky-list {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
	transition: top 0.1s ease-in-out;
}

.sticky-list-relative {
	position: sticky;
	top: 0;
}

.sticky-list-fixed {
	position: fixed;
	top: 0;
}

.sticky-list-avoid-top-bar {
}

.sticky-list h4 {
	margin: 0;
	padding-top: 0.5em;
}

.indented {
	background-color: ghostwhite;
	padding-left: 25% !important;
	margin: 0;
}

.mdc-list-divider {
	margin: 0.5em 0;
}

</style>
